:root {
    --black: #0D0D0D;
    --brown: #3A2F34;
    --white: #F3F3F2;
    --bege: #C6AE96;
    --pink: #A23157;
}

.header-page-sobrenos {
    background-color: var(--white);
}

.redes-sociais {
    display: flex;
    width: 100%;
    height: 20px;
    margin-bottom: 25px;
    align-items: center;
}


.rede-header4 {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    cursor: pointer;
}

.rede-header5 {
    width: 18px;
    height: 18px;
    margin-top: -20px;
    margin-right: 10px;
    cursor: pointer;
}

.body-sobrenos {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
}

body {
    overflow-x: hidden;
}

.h1-body-sobrenos {
    height: 50vh;
    width: 70%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 24%;
    font-family: 'Comfortaa';
    font-size: 60px;
}

body {
    background-color: var(--white);
}

.body-projects {
    background-image: none;
}

.banner-sobrenos-socia {
    width: 100%;
    background-image: linear-gradient(to right, #E7E8E3 0%, var(--white) 100%);
}

.paragrafo-principal-sobrenos {
    font-size: 26px;
    width: 80%;
    line-height: 40px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Comfortaa';
}

.paragrafo-principal-sobrenos2 {
    font-size: 30px;
    width: 40%;
    padding-right: 20%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.content-apresentação {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 200px;
    height: 40vh;
    line-height: 22px;
    font-family: 'Comfortaa';
    margin-bottom: 200px;
}

.banner-servicos2 {
    width: 36%;
    height: 50%;
    object-fit: cover;
    position: absolute;
    border-radius: 12px;
    z-index: 2;
}

.background-foto-servicos2 {
    width: 100%;
    margin-top: 20px;
    height: 80vh;
    z-index: 0;
}

.paragrafo-menor-sobre {
    width: 45%;
    line-height: 24px;
    font-family: 'Ubuntu';
}

.frase-sobrenos-negrito {
    width: 80%;
    font-size: 26px;
    font-family: 'Comfortaa';
    font-weight: 1000;
    margin-top: 7px;
    text-shadow: 0.3px 0.2px #0D0D0D;
}

.textos-page-sobrenos {
    width: 100%;
    display: flex;
    background-image: linear-gradient(to right, #E7E8E3 0%, var(--white) 100%);
    flex-direction: column;
    align-items: center;
}

.container-sobrenos-pagesobre {
    width: 100%;
    justify-content: center;
    background-color: var(--white);
    display: flex;
    align-items: center;
}

.content-sobrenos-pagesobre {
    display: flex;
    width: 70%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.content-sobrenos-pagesobre article {
    display: flex;
    width: 60%;
    /* padding: 30px 20px 30px 20px; */
    color: var(--black);
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.content-page-sobrenos-div {
    width: 90%;
    display: flex;
    color: var(--black);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap
}

.content-sobrenos-pagesobre h1,
.content-fazemparte h2 {
    margin-bottom: 10px;
    font-size: 25px;
}

.container-fazemparte h2 {
    margin-top: 20px;
    font-size: 25px;
}

.content-sobrenos-pagesobre p:nth-child(2) {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 1000;
}

.paragrafo-italic{
    margin-bottom: 25px;
    font-weight: 0;
    font-size: 16px;
    font-style: italic;
}

.content-sobrenos-pagesobre img {
    width: 19vw;
    object-fit: cover;
    height: 55vh;
}

.container-fazemparte {
    width: 70%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.content-fazemparte {
    width: 30%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 60px;
}

.container-fazemparte img {
    width: 19vw;
    object-fit: cover;
    height: 55vh;
}

.container-fazemparte p {
    width: 100%;
    font-style: italic;
    font-size: 16px;
}

.title-tambem-fazem {
    font-size: 30px;
    font-family: 'Comfortaa';
    margin-top: 90px;
    text-align: center;
    width: 63%;
    margin-bottom: 70px;
}

.container-fazemparte h6 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 1000;
}

.responsive-contsiner-sobre {
    display: none;
}

.not-responsive-contasiner-sobre {
    display: flex;
}

.div-row-servicos3 {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 900px) {

    .content-fazemparte {
        width: 95%;
    }

    .title-tambem-fazem {
        margin-top: 60px;
        margin-bottom: 50px;
        width: 90%;
        font-size: 22px;
    }

    .container-fazemparte h1 {
        font-size: 20px;
        width: 90%;
        text-align: center;
    }

    .not-responsive-contasiner-sobre {
        display: none;
    }

    .paragrafo-principal-sobrenos,
    .paragrafo-principal-sobrenos2,
    .frase-sobrenos-negrito {
        font-size: 22px;
        width: 90%;
        line-height: 30px;
        padding: 0;
    }

    .paragrafo-principal-sobrenos2 {
        margin-top: 35px;
    }

    .content-apresentação {
        width: 90%;
        flex-direction: column;
    }

    .content-apresentação h2 {
        width: 100%;
        font-size: 30px;
    }

    .paragrafo-menor-sobre {
        width: 100%;
        margin-top: 30px;
    }

    .h1-body-sobrenos {
        height: 25vh;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        padding-top: 10%;
        margin-left: 0;
        padding-left: 0;
        font-family: 'Comfortaa';
        font-size: 35px;
        width: 90%;
    }

    .content-sobrenos-pagesobre {
        flex-direction: column;
    }

    .content-sobrenos-pagesobre article {
        width: 100%;
        margin-bottom: 30px;
    }

    .content-page-sobrenos-div {
        width: 100%;
        color: var(--white);
    }

    .content-sobrenos-pagesobre img,
    .container-fazemparte img {
        width: 100%;
        object-fit: cover;
        height: 55vh;
    }

    .content-page-sobrenos-div h1 {
        margin-top: 30px;
    }

    .responsive-contsiner-sobre {
        display: flex;
    }

    .banner-servicos2 {
        width: 90vw;
        height: 40%;
        object-fit: cover;
        border-radius: 12px;
        z-index: 2;
        left: 5vw;
    }

    .content-apresentação {
        height: 100%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .background-foto-servicos2 {
        width: 220%;
        margin-top: 5px;
        margin-left: -25vw;
        height: 55vh;
        z-index: 0;
    }

}