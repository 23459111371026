@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

* {
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background-color: #C6AE96;
}

::-webkit-scrollbar {
  width: 10px;
  background: #C6AE96;
}

::-webkit-scrollbar-thumb {
  background: #978674;
}

a {
  text-decoration: none;
}

:root {
  --black: #0D0D0D;
  --brown: #3A2F34;
  --white: #F3F3F2;
  --bege: #C6AE96;
  --pink: #A23157;
}

.banner-principal {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  background-color: #0d0d0d41;
}


/* .background-banner {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-image: url('./img/01-sala de estar.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  z-index: -1;
  opacity: 1;
} */

.header {
  width: 100%;
  height: 85px;
  position: fixed;
  transition: 0.5s;
  color: var(--black);
  display: flex;
  z-index: 10;
}

.header2 {
  width: 100%;
  height: 85px;
  transition: 0.5s;
  color: var(--black);
  display: flex;
  background-color: #fff;
}

.carrossel-banner {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.carrossel-banner img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.scroll-up {
  visibility: visible;
  opacity: 1;
  background-color: transparent;
  transition: visibility 0s, opacity 0.1s linear;
}

.a-header {
  color: var(--white);
  transition: 0.3s;
  z-index: 10;
}

.a-header-color2 {
  background-color: var(--black) !important;
}

.a-header:hover {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--white);
}


.scroll-down {
  background-color: var(--white);
  /* visibility: hidden; */
  transition: visibility 0s, opacity 0.1s linear;
}

footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);
  justify-content: center;
  color: var(--white);
}

footer h2 {
  margin-top: 30px;
  letter-spacing: 1.3px;
  margin-bottom: 20px;
  width: 80%;
  font-size: 12px;
}

.not-responsive,
.responsive {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

footer nav {
  width: 85%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.footer nav p {
  color: var(--white);
}

.logo-footer {
  width: 40%;
  height: 70%;
  display: flex;
  flex-direction: column;
  color: var(--white);
  align-items: flex-start;
}

.logo-footer p {
  width: 100%;
  text-align: center;
}

.logo-footer img {
  width: 60%;
}

footer h1 {
  font-size: 16px;
}

.contact-footer {
  width: 40%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  color: var(--white);
}

.contact-footer h1 {
  font-size: 16px;
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.logo-instagram-footer {
  width: 20px;
  margin-right: 15px;
  transition: 0.4s;
}

.logo-instagram-footer path {
  fill: var(--white);
}

.contact-footer div:hover .logo-instagram-footer path {
  fill: var(--black)
}

.contact-footer div:hover {
  background-color: var(--white);
  color: var(--black);
}

.contact-footer a {
  width: 70%;
  height: 60px;
  margin-bottom: 30px;
  margin-top: 25px;
  align-items: center;
  color: var(--white);
  justify-content: center;
  cursor: pointer;
}

.contact-footer div {
  width: 100%;
  border: 2px solid var(--white);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.area-responsive-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo-header {
  width: 200px;
  margin-left: 30px;
}

.navigation-header-page {
  width: 480px;
  background-color: var(--pink);
  display: flex;
  padding: 10px 25px 10px 25px;
  border-radius: 8px 0 0 8px;
  justify-content: flex-end;
}

.newwidth {
  width: 12%;
}

.newwidth a {
  color: #0D0D0D;
}

.navigation-header-page ul,
.navigation-header-socialmedia ul {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  font-size: 0;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}

.navigation-header-page-responsive ul {
  width: 100%;
  height: 65vh;
  margin-top: 10vh;
  font-size: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  padding: 0;
}

.instagramPage {
  width: 60px;
  left: 0;
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  bottom: -32vh;
}

.navigation-header-socialmedia ul {
  width: 65%;
}

.navigation-header-page ul>li {
  margin-left: 0;
  display: inline-block;
  color: var(--white);
  font-size: 15px;
  cursor: pointer;
  /* background-color: var(--bege); */
}

.rede-header {
  width: 17px;
  cursor: pointer;
}

.rede-header2 {
  width: 15px;
  cursor: pointer;
}

.rede-header3 {
  width: 12px;
  cursor: pointer;
}

.rede-header path,
.rede-header2 path,
.rede-header3 path {
  fill: var(--white) !important;
}

.navigation-header-page-responsive ul>li {
  margin-left: 0;
  font-size: 35px;
  cursor: pointer;
}

.navigation-header-socialmedia {
  width: 15%;
  display: flex;
  justify-content: flex-end;
}

.social-media-responsive {
  justify-content: center;
  width: 30%;
  height: 10vh;
}

.navigation-header-socialmedia img {
  width: 20px;
  height: 20px;
}

.content-banner-principal {
  width: 80%;
  height: 100%;
  display: flex;
  padding-top: 3%;
  position: absolute;
  align-items: center;
  top: 0;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.content-banner-principal h1 {
  max-width: 500px;
  min-width: 300px;
  font-family: 1000;
  line-height: 120%;
  font-family: 'Comfortaa';
  color: var(--white);
  font-size: 85px;
  margin-bottom: 50px;
}

.content-banner-principal button {
  width: 165px;
  height: 60px;
  background-color: var(--bege);
  border: none;
  outline: none;
  font-size: 17px;
  cursor: pointer;
  transition: 0.3s;
  font-family: 'Ubuntu';
  box-shadow: 0px 3px 6px #00000021;
}

.content-banner-principal a {
  color: var(--white);
  text-decoration: none;
}

.content-banner-principal button:hover {
  background-color: #c6ae96bb;
}

.area-projetos {
  color: var(--black);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.area-projetos2 {
  color: var(--black);
  width: 100%;
  justify-content: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-projetos {
  display: flex;
  width: 80%;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card-image-project {
  width: 30%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-project {
  width: 100%;
  height: 60vh;
  justify-content: space-between;
  display: flex;
  margin-bottom: 70px;
}

.content-sobrenos {
  width: 80%;
  height: 100%;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
}

.card-project img {
  width: 70%;
  object-fit: cover;
}

.content-sobrenos img {
  height: 90%;
  width: 50%;
  object-fit: cover;
}

.saiba-mais-projetos-home h2 {
  font-size: 20px;
  border-top: 2px solid #A23157;
  padding-top: 10px;
  width: 90%;
  margin-bottom: 30px;
}

.paragrafro-textos-padrao {
  font-size: 16px;
  width: 100%;
  margin-bottom: 30px;
  line-height: 24px;
}

.saiba-mais-projetos-home h6 {
  font-size: 14px;
  margin-bottom: 30px;
}

.saiba-mais-projetos-home h4 {
  border-bottom: 2px solid #A23157;
  width: 90%;
  transition: 0.3s;
  font-size: 16px;
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
}

.card-project:hover h4,
.sobre-nos-home-texto:hover h4 {
  color: var(--pink);
  width: 38%;
}

.card-image-project img {
  height: 400px;
  width: 100%;
  margin-bottom: -5px;
  object-fit: cover;
}

.container-projetos div:nth-child(3) {
  margin-right: 0;
}

.card-image-project img {
  width: 100%;
  margin-right: 3%;
  transition: 0.2s;
  cursor: pointer;
}

.card-image-project img:hover {
  opacity: 0.5;
}

.area-projetos h1,
.servicos-home h1 {
  font-size: 38px;
  font-family: 'Comfortaa';
  margin-bottom: 50px;
}


.container-projetos h1 {
  font-size: 18px;
  margin-right: 3%;
  width: calc(100% - 10px);
  display: flex;
  height: 40px;
  color: var(--black);
  align-items: center;
  padding-left: 10px;
  background-color: var(--white);
  position: relative;
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 500ms;
}

.saiba-mais-projetos {
  font-size: 18px;
  margin-right: 3%;
  width: calc(100% - 10px);
  display: flex;
  height: 260px;
  color: var(--black);
  align-items: center;
  flex-direction: column;
  bottom: 260px;
  padding-left: 10px;
  background-color: var(--white);
  position: relative;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 400ms;
  margin-bottom: -180px;
}

.saiba-mais-projetos-home {
  font-size: 18px;
  width: 25%;
  display: flex;
  height: 100%;
  color: var(--black);
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  bottom: 260px;
  padding-left: 10px;
}

.sobre-nos-home-texto {
  width: 45%;
  height: 90%;
  margin-left: 20px;
  justify-content: flex-end;
}

.servicos-home {
  width: 100%;
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
}

.servicos-home h1 {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.saiba-mais-projetos h6 {
  width: 100%;
  font-size: 15px;
  margin-bottom: 28%;
}

.content-card-servicos {
  width: 90%;
  max-width: 1150px;
  display: flex;
  flex-wrap: wrap;
  background-image: url('./img/Group\ 1\ \(11\).png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  padding-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  min-height: 40vh;
}

.swal2-styled.swal2-confirm {
  background-color: var(--pink) !important;
}

.card-servicos:hover {
  width: 290px;
  height: 250px;
}

.card-servicos:hover p{
  font-size: 12.5px;
  width: 185px;
}

.card-pic-servicos {
  width: 95%;
}

.areabutton-servicos {
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
  display: flex;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
}

.text-servicos-area {
  font-size: 24px;
  width: 100%;
  text-align: center;
}

.area-projetos-h2 {
  width: 100%;
  height: 50px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.areabutton-servicos h2 {
  display: flex;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--black);
  transition: 0.2s;
}

.area-projetos-h2:hover h2,
.areabutton-servicos:hover h2 {
  color: var(--pink);
  border-bottom: 2px solid var(--pink);
}

.area-projetos-h2 h2 {
  display: flex;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--black);
}

/* .area-projetos-h2 h2:hover, .area-projetos-h2 h2:hover h3{
  color: #A23157;
}

.area-projetos-h2 h2:hover {
  border-bottom: 2px solid #A23157;
} */

.area-projetos-h2 h3 {
  font-size: 20px;
  margin-left: 6px;
  color: var(--black);
}

.areabutton-servicos h3:hover {
  color: var(--bege);
}

.areabutton-servicos button {
  width: 150px;
  height: 40px;
  margin-left: 20px;
}

.card-servicos,
.card-servicos2 {
  width: 255px;
  height: 220px;
  display: flex;
  justify-content: center;
  background-image: url('./img/Prancheta\ 68@3x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 45px;
  transition: 0.3s;
  color: var(--white);
}

.selected {
  width: 50%;
}

.onNavHover {
  opacity: 0.5;
}

/* .outline1:hover~.outline2,
.outline1:hover~.outline3,
.outline1:hover~.outline4 {
  opacity: 0.5;
} */

/* .outline2:hover .outline1, .outline2:hover +.outline3, .outline2:hover ~.outline4 {
  opacity: 0.5;
} 
.outline3:hover ~.outline2, .outline3:hover ~.outline1, .outline3:hover ~.outline4 {
  opacity: 0.5;
} 
.outline4:hover ~.outline1, .outline4:hover ~.outline2, .outline4:hover ~.outline3 {
  opacity: 0.5;
}  */


.saiba-mais-projetos h2 {
  width: 100%;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.saiba-mais-projetos h4 {
  width: calc(100% - 10px);
  font-size: 18px;
  margin-right: 10px;
  border-bottom: 1px solid #0D0D0D;
  padding-bottom: 2px;
}

.container-projetos div:hover .saiba-mais-projetos2 {
  transform: scaleY(0);
  transform-origin: bottom;
}

.saiba-mais-projetos2 {
  height: 40px !important;
  padding-top: 10px !important;
  padding-right: 5px;
  display: flex;
  align-items: flex-start !important;
  width: calc(100% - 15px) !important;
}

.saiba-mais-projetos {
  background-color: rgb(251, 251, 251) !important;
}

.container-projetos div:hover .saiba-mais-projetos {
  transform: scaleY(1);
  transform-origin: bottom;
}

.form {
  width: 100%;
  /* background-image: url("./img/tropical-green-leaves-background.jpg"); */
  /* background-size: cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bege);
  color: var(--black);
}

.container-forms {
  display: flex;
  height: 100%;
  font-family: 'Comfortaa';
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-forms h1 {
  font-size: 30px;
  margin-top: 50px;
}

.container-forms p {
  font-size: 17px;
}

.input-form {
  margin-top: 45px;
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.input-form input {
  width: 270px;
  height: 45px;
  padding-left: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: rgb(110, 110, 110);
  outline: none;
  font-family: 'Ubuntu';
}

.input-form select {
  width: 307px;
  height: 53px;
  color: rgb(110, 110, 110);
  padding-left: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  outline: none;
  font-family: 'Ubuntu';
}

.input-form textarea {
  width: 600px;
  margin-right: 20px;
  height: 100px;
  padding-left: 30px;
  padding-top: 10px;
  margin-bottom: 20px;
  font-family: 'Ubuntu';
}

.button-medio {
  width: 250px;
  height: 50px;
  font-family: 'Ubuntu';
  margin-top: 20px;
  background-color: #A23157;
  border: none;
  outline: none;
  font-size: 16px;
  margin-bottom: 30px;
  cursor: pointer;
  color: var(--white);
  transition: 0.3s;
  box-shadow: 0px 3px 6px #00000021;
}

.button-medio:hover {
  background-color: #a23157c4;
}

.container-sobrenos {
  color: var(--pink);
  width: 100%;
  justify-content: center;
  display: flex;
  min-height: 80vh;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 30px;
  flex-direction: column;
}

.container-sobrenos h1 {
  color: var(--white);
  font-family: 'Comfortaa';
  font-size: 30px;
  height: 70px;
  padding-top: 30px;
}

/* 
.content-sobrenos {
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
} */

.content-sobrenos article {
  display: flex;
  width: 46%;
  position: relative;
  right: 40px;
  padding: 30px 20px 30px 20px;
  background-color: var(--white);
  flex-direction: column;
  justify-content: center;
}

.content-sobrenos h1 {
  margin-bottom: 30px;
  font-size: 35px;
}

.content-sobrenos p {
  margin-bottom: 40px;
  font-size: 16px;
}

.content-sobrenos button {
  width: 100%;
  border: none;
  outline: 0;
  cursor: pointer;
  background-color: var(--bege);
  font-family: 'Ubuntu';
  height: 40px;
  font-size: 16px;
  color: var(--white);
  transition: 0.3s;
}

.content-sobrenos button a {
  color: var(--white);
}

.content-sobrenos button:hover {
  background-color: #c6ae96ae;
}

.fotodonas {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.fotoarticle {
  width: 90%;
}

/* .content-sobrenos div:nth-child(1) {
  height: 80vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

/* .content-sobrenos div:nth-child(1) a{
  color: var(--white);
  width: 100%;
} */

/* .content-sobrenos div:nth-child(2) {
  height: 80vh;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.areabutton-servicos a {
  color: var(--black);
}

.area-projetos-h2 a {
  color: var(--black);
}

.container-servicos {
  width: 100%;
  padding: 5vh 0 5vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('./img/padrao-de-fundo-abstrato-de-textura-de-marmore-preto-para-o-projeto_158502-344.png'); */
  background-color: var(--white);
  background-size: contain;
}

.container-servicos h1 {
  color: var(--black);
  font-size: 28px;
  margin-bottom: 60px;
}

.content-servicos {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.area-servicos {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
}

.area-servicos2 {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.area-servicos2 img {
  width: 10%;
  margin-bottom: 15px;
}

.row-servicos-teste {
  width: 100vw;
  flex-wrap: wrap;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-servicos {
  width: 40%;
  height: 60%;
  object-fit: cover;
  position: absolute;
  margin-bottom: 1%;
  border-radius: 12px;
  z-index: 2;
}

.background-foto-servicos {
  width: 80%;
  height: 100%;
  z-index: 0;
}

.swiper-pagination-bullet {
  background: transparent !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #A23157 !important;
}


.row-servicos-teste button {
  width: 200px;
  height: 60px;
  border: none;
  outline: 0;
  cursor: pointer;
  background-color: var(--black);
  color: var(--white);
}

.div-row-servicos {
  width: 45%;
  height: 100%;
}

.div-row-servicos p {
  font-size: 16px;
  line-height: 24px;
}

.div-row-servicos-home {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div-row-servicos-home p {
  font-size: 10.5px;
  text-align: center;
  width: 160px;
  line-height: 15px;
}

.div-row-servicos2 {
  width: 40%;
  min-width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: 4%;
  align-items: center;
}

.div-row-servicos-home img {
  width: 25%;
  margin-bottom: 20px;
}

.div-row-servicos img {
  width: 20%;
  margin-bottom: 20px;
}

.div-row-servicos h2 {
  font-family: 'Comfortaa';
}

.div-row-servicos-home img {
  margin: 0;
  margin-right: 15px;
}

.div-row-servicos-home h2 {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 0;
  text-align: center;
  justify-content: center;
  display: flex;
  height: 30px;
  align-items: center;
  width: 60%;
  margin-top: 15%;
  font-family: 'Comfortaa';
}

.row-servicos-teste div p {
  width: 80%;
  margin-top: 10px;
  color: grey;
  position: relative;
}

.div-row-servicos-home img {
  margin-top: 0;
  margin-bottom: 10px;
}

.alinhamento-row-direita {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.alinhamento-row-direita p {
  text-align: end;
}

.area-servicos-bottom {
  width: 75%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.area-servicos-bottom button {
  width: 200px;
  height: 60px;
  border: none;
  outline: 0;
  cursor: pointer;
  background-color: var(--white);
}

.area-servicos div {
  width: 28%;
  height: 100%;
  background-color: var(--white);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.area-servicos div hr,
.row-servicos-teste hr {
  width: 10%;
  height: 0.5px;
  background-color: var(--black);
}

.area-servicos div p {
  width: 90%;
  text-align: center;
  margin-top: 20px;
}

.row-servicos-teste h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--black);
}

.area-servicos img {
  width: 55%;
  margin: 40px 0 30px 0;
}

.new-project {
  display: flex;
  align-items: center;
  height: 20vh;
  width: 94%;
  justify-content: center;
  margin-bottom: 40px;
  display: none;
}

.new-project button {
  width: 20%;
  height: 50%;
  background-color: var(--white);
  border: none;
  cursor: pointer;
  color: #0D0D0D;
  transition: 00.3s;
}

.new-project button:hover {
  background-color: #f3f3f2b5;
}

/* HELPER CLASSES */
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/***********************/
/* NAVIGATION MENU */
/***********************/

/* OVERLAY */
.overlay {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay-slide-right {
  transition: all 0.4s ease-in-out;
  transform: translateX(0);
}

.overlay-slide-left {
  transition: all 0.8s ease-in-out;
  transform: translateX(-100vw);
}

.overlay nav ul li a {
  color: var(--white);
}

/* NAV MENU ITEMS */
.overlay nav ul {
  height: 100vh;
  list-style: none;
}

.overlay nav ul li {
  height: 20%;
  width: 60vw;
}

.overlay nav li:nth-of-type(1) {
  background-color: var(--pink);
}

.overlay nav li:nth-of-type(2) {
  background-color: #cccccc;
}

.overlay nav li:nth-of-type(3) {
  background-color: var(--bege);
}

.overlay nav li:nth-of-type(4) {
  background-color: var(--pink);
}

.overlay nav li:nth-of-type(5) {
  background-color: #cccccc;
}

.overlay nav li a {
  letter-spacing: 0.2rem;
  font-size: 2rem;
}

.overlay nav li a:hover,
.overlay nav li a:active {
  transform: scale(1.1);
}

.overlay nav li a:hover:after {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  margin-left: 1rem;
}

/***********************/
/* NAV SLIDE IN ANIMATION */
/***********************/

.slide-in-1 {
  animation: slide-in 0.4s linear 0.1s both;
}

.slide-in-2 {
  animation: slide-in 0.4s linear 0.2s both;
}

.slide-in-3 {
  animation: slide-in 0.4s linear 0.3s both;
}

.slide-in-4 {
  animation: slide-in 0.4s linear 0.4s both;
}

.slide-in-5 {
  animation: slide-in 0.4s linear 0.5s both;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

/***********************/
/* NAV SLIDE OUT ANIMATION */
/***********************/

.slide-out-1 {
  animation: slide-out 0.3s linear 0.25s both;
}

.slide-out-2 {
  animation: slide-out 0.3s linear 0.2s both;
}

.slide-out-3 {
  animation: slide-out 0.3s linear 0.15s both;
}

.slide-out-4 {
  animation: slide-out 0.3s linear 0.1s both;
}

.slide-out-5 {
  animation: slide-out 0.3s linear 0.5s both;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

/***********************/
/* HAMBURGER MENU ANIMATION */
/***********************/

.hamburger-menu {
  position: fixed;
  top: 2%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}

.menu-bar1,
.menu-bar2,
.menu-bar3 {
  width: 2.7rem;
  height: 3px;
  background-color: var(--pink) !important;
  margin: 10px;
  transition: 0.4s;
}

.title-sobrenos {
  display: none;
}

.a-header-color {
  color: var(--black) !important;
}

/* .menu-bar2 {
  width: 2rem;
  margin-left: auto;
} */

/* ROTATE FIRST BAR */
.active .menu-bar1 {
  /* transform: rotate(-45deg) translate(-7px, 8px); */
  transform: rotate(-45deg) translate(-0.4rem, 0.8rem);
  background-color: var(--white) !important;
}

/* FADE OUT SECOND BAR */
.active .menu-bar2 {
  opacity: 0;
}

/* ROTATE LAST BAR */
.active .menu-bar3 {
  /* transform: rotate(45deg) translate(-6px, -8px); */
  transform: rotate(45deg) translate(-0.4rem, -0.8rem);
  background-color: var(--white) !important;
}

.responsive-header {
  display: none;
}

.hamburger-menu {
  display: none;
}

.content-sobrenos img {
  width: 50%;
  object-fit: cover;
}

@media only screen and (max-width: 1015px) {

  .hamburger-menu {
    display: block;
  }

  /* .card-image-project {
    width: 300px;
  } */

  footer h2 {
    margin-top: 30px;
    letter-spacing: 1.3px;
    margin-bottom: 20px;
    width: 80%;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .not-responsive {
    display: none;
  }

  .responsive-header {
    display: flex;
  }

  .not-responsive {
    height: 100%;
    display: none;
    align-items: flex-start;
    background-color: var(--white);
  }

  .content-sobrenos {
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  .content-sobrenos article {
    display: flex;
    width: 70%;
    right: 0;
    font-size: 12px;
    bottom: 20px;
    flex-direction: column;
    align-items: center;
  }

  .article-img {
    width: 60vw;
    height: 100vh;
    position: relative;
    right: 10vw;
  }

  .content-sobrenos div:nth-child(1) {
    width: 100%;
    height: 40vh;
  }

  /* .content-sobrenos div:nth-child(2) {
    height: 60vh;
    width: 100%;
  } */

  .title-sobrenos {
    display: flex;
  }

  .fotoarticle {
    width: 60%;
  }

  .container-sobrenos button {
    margin-bottom: 20px;
  }

  .content-sobrenos div h1 {
    display: none;
  }

  .container-sobrenos {
    align-items: center;
  }

  .container-sobrenos p {
    font-size: 12px;
  }

  .content-sobrenos h1 {
    font-size: 25px;
  }


  .area-servicos,
  .area-servicos-bottom {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .area-servicos-bottom button,
  .area-servicos div {
    margin-bottom: 50px;
  }

  .saiba-mais-projetos-home {
    font-size: 18px;
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 20px;
  }

  .content-card-servicos {
    max-width: 680px;
    background-image: none;
    justify-content: space-between;
    align-items: center;
  }

  .card-servicos {
    margin-bottom: 30px;
  }

}

@media only screen and (max-width: 1450px) {


  .content-sobrenos p {
    font-size: 14px;
  }

  .content-sobrenos img {
    width: 60%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 900px) {
  /* .content-sobrenos div:nth-child(2) {
    height: 70vh;
  } */

  .fotoarticle {
    width: 70%;
  }

}

@media only screen and (max-width: 700px) {
  
  .text-servicos-area {
    font-size: 19px;
    width: 85%;
  }
  .card-image-project {
    width: 47%;
  }

  .saiba-mais-projetos {
    height: 270px;
  }

  .content-card-servicos {
    justify-content: center;
  }

  /* .content-sobrenos div:nth-child(2) {
    height: 60vh;
  } */

  .content-sobrenos img {
    width: 100%;
    height: 40%;
  }

  .fotoarticle {
    width: 80%;
  }
}

.responsive-home-projetos2 {
  display: none;
}

.body-contato {
  background-color: var(--bege);
  position: absolute;
  top: 0;
  z-index: 0;
}

@media only screen and (max-width: 550px) {

  .responsive-home-projetos2 {
    display: flex;
  }

  .card-servicos {
    width: 325px;
    height: 280px;
  }

  .card-servicos:hover {
    width: 325px;
    height: 280px;
  }

  .card-servicos:hover p{
    font-size: 12px;
    width: 70%;
  }

  /* .obras-serviços {
    margin-top: 20%;
  } */

  .div-row-servicos-home h2 {
    margin-top: 20px;
    width: 50%;
  }

  .div-row-servicos-home p {
    font-size: 12px;
    text-align: center;
    width: 70%;
    margin-top: 10px;
    line-height: 17px;
  }

  .responsive-home-projetos {
    display: none;
  }

  .card-project {
    width: 100%;
    height: 50vh;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .card-project img {
    height: 60%;
    width: 90vw;
    object-fit: cover;
  }

  .saiba-mais-projetos-home h2 {
    font-size: 16px;
    border-top: none;
    width: 100%;
    margin-bottom: 8px;
    padding-top: 0;
  }

  .saiba-mais-projetos-home h6 {
    font-size: 14px;
    width: 97%;
    margin-bottom: 10px;
  }

  .banner-principal {
    height: 45vh;
  }

  .carrossel-banner img {
    height: 45vh;
  }

  .saiba-mais-projetos-home h4 {
    border-bottom: none;
    width: 90%;
    font-size: 18px;
    height: 35px;
  }

  /* .content-sobrenos div:nth-child(2) {
    height: 50vh;
  } */

  .fotoarticle {
    width: 100%;
  }

  .row-servicos-teste div p {
    width: 90%;
  }


  .input-form select {
    margin-right: 0;
  }

  .div-row-servicos {
    width: 75%;
  }

  .div-row-servicos2 {
    width: 100%;
  }
  
  .banner-servicos {
    width: 90%;
    height: 250px;
  }

  .content-banner-principal h1 {
    font-size: 41px;
    max-width: 300px;
    min-width: 250px;
  }

  .input-form {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .input-form input {
    margin-right: 0;
  }

  .input-form textarea {
    width: 270px;
    margin: 0;
    margin-bottom: 25px;
  }

  .container-forms h4 {
    font-size: 15px;
    width: 70%;
    text-align: center;
  }

  .container-forms h1 {
    font-size: 20px;
  }

  .area-projetos p {
    font-size: 17px;
    max-width: 320px;
    min-width: 320px;
  }

  .area-projetos h1,
  .servicos-home h1 {
    font-size: 28px;
  }

  .card-image-project {
    width: 90%;
    padding: 5%;
  }

  .container-projetos h1 {
    font-size: 16px;
    margin-bottom: -50px;
  }

  footer nav {
    flex-direction: column;
  }

  .logo-footer {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .contact-footer {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .contact-footer h1 {
    display: none;
  }

  .logo-footer img {
    width: 90%;
  }

  .logo-footer p {
    display: none;
  }

  .logo-header {
    width: 180px;
    margin-left: 20px;
  }

}
