:root {
    --black: #0D0D0D;
    --brown: #3A2F34;
    --white: #F3F3F2;
    --bege: #C6AE96;
    --pink: #A23157;
}

@font-face {
    font-family: 'Comfortaa';
    src: local('Comfortaa'), url('./fonts/Comfortaa-Regular.ttf') format('truetype');
}

.banner-to-header {
    width: 100%;
}

.img-banner-project {
    width: 100vw;
    position: relative;
    height: 90vh;
    margin-bottom: 45px;
    object-fit: cover;
}

.input-add-file {
    display: none;
}

.AdicionadoAdd {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    color: #F3F3F2;
    font-family: 'ubuntu';
    font-size: 14px;
    background-color: #31a23a;
}

.label-add {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    color: #F3F3F2;
    cursor: pointer;
    font-family: 'ubuntu';
    border: none;
    font-size: 14px;
    background-color: #A23157;
}

/* .label-add {
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F3F3F2;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    left: 100px;
    z-index: 2;
    background-color: #C6AE96;
} */

.label-add2:hover {
    background-color: #c6ae96e0;
}

.input-to-write {
    width: 47.5%;
    font-family: 'Ubuntu';
    border-radius: 6px;
    height: 40px;
    padding-left: 10px;
    border: 1px solid #A23157;
    box-shadow: 2px 4px 10px #ccc;
    background-color: #fff;
    outline: none;
    transition: 0.2s;
}

.textarea-to-write, .textarea-to-write2 {
    width: calc(100% - 14px);
    height: 15vh;
    padding-left: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid #A23157;
    box-shadow: 2px 4px 10px #ccc;
    background-color: #fff;
    outline: none;
    transition: 0.2s;
}

.textarea-to-write2 {
    width: 100%;
    margin-top: 30px;
}

.input-to-write-discription {
    width: calc(100% - 14px);
    height: 8vh;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 6px;
    border: 1px solid #A23157;
    box-shadow: 2px 4px 10px #ccc;
    background-color: #fff;
    outline: none;
    transition: 0.2s;
}

.input-to-write:focus, .input-to-write-discription:focus, .textarea-to-write:focus {
    border: 2px solid #A23157;
}

.input-form textarea:focus, .input-form input:focus {
    box-shadow: 2px 4px 10px #ccc;
}

.paragrafo-principal {
    margin-bottom: 40px;
}

.button-forms {
    width: 20%;
    height: 40px;
    margin-left: 40%;
    cursor: pointer;
    border: none;
    margin-top: 5px;
    font-family: 'Ubuntu';
    background-color: var(--pink);
    color: #F3F3F2;
}

.content-foto-banner-add {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.screen-area-projetos {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--white);
}

.form-add {
    background-color: #fff;
}

.screen-area-projetos2 {
    width: 100%;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    flex-direction: column;
}

.screen-area-projetos3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--white);
}

.screen-view-projets2 {
    width: 70%;
    box-shadow: 2px 0 10px 2px #ccc;
    border-radius: 8px;
    z-index: 2;
    width: 0;
}

body {
    font-family: 'Ubuntu';
}

.title-page-projects {
    font-size: 40px;
    margin-bottom: 30vh;
    margin-top: 100px;
}

.estrutura-projeto {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.area-planta {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}


.estrutura-projeto {
    font-family: 'Ubuntu';
}

.area-antes-e-depois img {
    width: 47%;
}

.text-planta-project {
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Ubuntu';
    flex-direction: column;
}

.text-planta-project h1 {
    font-family: Comfortaa;
    font-size: 50px;
    width: 55%;
}

.text-planta-project p {
    font-size: 18px;
    width: 55%;
    margin: 20px 0 20px 0;
}

.inputsiniciais {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    justify-content: space-between;
}

.inputsiniciais input:nth-child(1) {
    margin-right: 10px;
}

.area-antes-e-depois {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px;
}

.area-antes-e-depois p {
    width: 100%;
    line-height: 24px;
    margin-top: 20px;
    text-align: left;
}

.area-antes-e-depois div {
    width: 47%;
    margin-bottom: 30px;
}

.imagens-projeto-principal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 80px;
}

.container-img-projeto-principal img {
    width: 48%;
    margin-bottom: 45px;
    object-fit: cover;
    height: 85vh;
}

.content-comodo {
    width: 100%;
}

.first-img-projeto {
    width: 100%;
    object-fit: cover;
    height: 85vh;
    margin-bottom: 45px;
}

.duple-image-project img {
    width: 47%;
    min-width: 200px;
    object-fit: cover;
    height: 85vh;
    margin-bottom: 45px;
}

.imagens-projeto-principal p, .paragrafo-principal {
    width: 100%;
    line-height: 24px;
    margin-bottom: 40px;
}

.container-img-projeto-principal {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.add-project-background {
    background-color: #fff;
    padding-top: 40px;
    min-height: calc(100vh - 40px);
    box-shadow: 2px 0 10px 2px #ccc;
    border-radius: 20px;
    padding-left: 6%;
    z-index: 2;
    width: 40%;
    flex-direction: column;
}

.add-project-background img {
    width: 200px;
    margin-left: -10px;
}

.add-project-background h1 {
    font-size: 38px;
    width: 100%;
    margin-top: 70px;
    color: var(--black);
    line-height: 55px;
    font-family: Comfortaa;
}

.add-project-background p {
    font-size: 20px;
    width: 50%;
    line-height: 35px;
    margin-top: 30px;
}

.form-cadastro {
    min-height: calc(100vh - 80px);
    padding: 40px;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    transition: width 1s ease-in-out;
}

.form-cadastro h1 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: Comfortaa;
}

.whatsimage-add {
    width: 30px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--pink);
    box-shadow: 2px 0 10px 2px #ccc;
    margin-bottom: 20px;
    margin-left: 15px;
}

.container-click-here {
    width: 250px;
    height: 130px;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 6px;
}

.my-telephone {
    display: flex;
}

.my-telephone h4 {
    color: #292929;
}

.my-telephone h5 {
    font-size: 10px;
    margin-top: 2px;
}


.my-telephone div {
    margin-left: 13px;
    margin-top: 6px;
    color: var(--black);
}

.container-click-here h6 {
    font-size: 14px;
    color: var(--black);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 15px;
}

.whatsimage-add path {
    fill: #fff !important;
}



@media only screen and (max-width: 1015px) {

    .estrutura-projeto {
        width: 90%;
    }

    .button-forms {
        width: 40%;
        height: 40px;
        margin-left: 30%;
        cursor: pointer;
        border: none;
        margin-top: 5px;
        font-family: 'Ubuntu';
        background-color: var(--pink);
        color: #F3F3F2;
    }

    .text-planta-project h1 {
        font-family: Comfortaa;
        font-size: 30px;
        width: 95%;
    }

    .area-antes-e-depois img {
        margin-top: 20px;
        width: 100%;
        height: 300px;
    }

    .text-planta-project {
        width: 100%;
    }

    .area-antes-e-depois div {
        width: 100%;
    }

    .imagens-projeto-principal img {
        width: 100%;
    }

    .container-img-projeto-principal img {
        height: 55vh;
    }
    
    .first-img-projeto {
        height: 40vh;
    }
    
    .duple-image-project img {
        height: 55vh;
    }

    .img-banner-project {
        height: 40vh;
    }

    .area-antes-e-depois div {
        width: 100%;
    }

}

.show2 {
    overflow: hidden;
    width: 70%;
    transition: width 0.5s all;
}

.switch {
    position: relative;
    /* display: inline-block; */
    width: 50px;
    /* margin-left: 5px; */
    height: 26px;
    margin-bottom: 25px;
}

.toggle-user {
    width: 100%;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.content-foto-dois {
    justify-content: space-between;
}

.content-toggle h1 {
    font-size: 7px;
    margin-top: -20px;
}

.content-toggle {
    height: 80px;
}

.button-comodo {
    width: 85% !important;
}

.toggle-user2 {
    width: 100%;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 12px;
}

.toggle-user2 h3 {
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    font-size: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #3F3867;
}


input:focus+.slider {
    box-shadow: 0 0 1px #3F3867;
}

input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.border-select-card {
    box-shadow: 0px 1px 8px #48828A;
    border: 1px solid #48828A;
}

.button-add-action-plan3 {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
}

.duple-image-project {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}